import React, { useEffect, useState } from "react";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Link } from "../../components/Link/Link";
import { Button } from "../../components/Button/Button";
import useWindowSize from "./useWindowSize";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

const WhatYouWillLearn = ({ data, ...props }) => {
  const [width] = useWindowSize();
  const [seeMore, setSeemore] = useState({ step: -1, status: false });
  const [listItem, setListItem] = useState([]);
  const { items, primary } = data || {};

  const handleSeemore = (step) => {
    setSeemore((pre) => {
      if (pre.step === step) {
        return { ...pre, step: step, status: !pre.status };
      } else return { ...pre, step: step, status: true };
    });
  };

  useEffect(() => {
    let listTamp = [...items];
    setListItem(listTamp.splice(0, 4));
  }, [items]);

  const handleListMore = () => {
    let listTamp = [...items];
    listItem.length !== items.length
      ? setListItem(listTamp)
      : setListItem(listTamp.splice(0, 4));
  };

  const CourseItem = ({ item, step }) => (
    <Item>
      {width > 650 && (
        <ItemStep
          tag="h3"
          font={typography.type.code}
          color={color.primary.blue20}
          weight={typography.weight.regular1}
          align="left"
          size={typography.size.code}
          lineHeight={90}
          capital="uppercase"
        >
          0{step}
        </ItemStep>
      )}

      <ItemInfo>
        <WrapItemTitlle>
          {width <= 650 && (
            <ItemStep
              tag="h3"
              font={typography.type.code}
              color={color.primary.blue20}
              weight={typography.weight.regular1}
              align="left"
              size={typography.size.code}
              lineHeight={90}
              capital="uppercase"
            >
              0{step}
            </ItemStep>
          )}
          <ItemTitle
            tag="h5"
            font={typography.type.code}
            color={color.netraul.black100}
            weight={typography.weight.regular1}
            align="left"
            size={typography.size.l1}
            lineHeight={36}
            capital="uppercase"
          >
            {item?.title?.text}
          </ItemTitle>
        </WrapItemTitlle>
        <ItemDescription
          font={typography.type.primary}
          weight={typography.weight.normal}
          align="left"
          size={typography.size.s3}
          lineHeight={26}
          className={
            seeMore.step === step && seeMore.status === true
              ? ""
              : width < 650
              ? "text-clamp"
              : ""
          }
        >
          {item?.description?.text}
        </ItemDescription>
        {width < 650 && (
          <Seemore onClick={() => handleSeemore(step)}>
            {seeMore.step === step && seeMore.status === true
              ? "See less"
              : "See more"}
          </Seemore>
        )}
      </ItemInfo>
    </Item>
  );
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <TitleHeader
        font={typography.type.code}
        color={color.primary.blue}
        weight={typography.weight.regular1}
        align="left"
        size={typography.size.code}
        lineHeight={90}
        capital="uppercase"
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </TitleHeader>
      <WrapBody>
        {listItem.map((item, index) => (
          <CourseItem key={index} item={item} step={index + 1} />
        ))}

        <GroupButton>
          <SeeMoreButton onClick={handleListMore} type="primary">
            {listItem.length !== items.length ? "See more" : "See less"}
          </SeeMoreButton>
        </GroupButton>
      </WrapBody>
    </WrapperSection>
  );
};

const GroupButton = styled.div`
  text-align: center;
`;
const SeeMoreButton = styled(Button)`
  padding: 12px 45px;
  border-radius: 40px;
  height: 65px;
`;
const Seemore = styled(Link)`
  color: ${color.primary.blue};
  font-weight: bold;
  font-size: 16px;
  margin-top: 14px;
  display: block;
`;

const WrapItemTitlle = styled.div`
  display: flex;
  margin-top: 11px;
`;
const ItemDescription = styled(Text)`
  display: block;
  color: #000;
  margin-top: 24px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 20px;
  }
`;
const ItemTitle = styled(Text)``;
const ItemInfo = styled.div``;
const ItemStep = styled(Text)`
  letter-spacing: -0.015em;
  display: inline-block;
  min-width: 67px;
  box-sizing: border-box;
  margin-right: 24px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 80px !important;
    line-height: 62px;
    margin-right: 14px;
    min-width: 54px;
  }
`;
const Item = styled.div`
  display: flex;
  margin-bottom: 54px;
  .text-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }
  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const WrapBody = styled.div`
  margin-top: 60px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const TitleHeader = styled(Text)`
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  /* margin-top: 150px; */
  padding: 150px 220px;
  background: #f4f6f8;
  @media (max-width: 1200px) {
    padding: 80px 40px;
  }
  @media (max-width: 992px) {
    padding: 80px 30px;
  }
  @media (max-width: 650px) {
    padding: 80px 20px;
  }
`;

WhatYouWillLearn.propTypes = {
  data: PropTypes.shape(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      descriptions: PropTypes.arrayOf(),
      includes: PropTypes.arrayOf(),
    })
  ),
};

WhatYouWillLearn.defaultProps = {
  data: {
    title: "What you’ll learn",
    list: [
      {
        step: "01",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
      {
        step: "02",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
      {
        step: "03",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
      {
        step: "04",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
      {
        step: "05",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
      {
        step: "06",
        title: "Foundations of Agile and Agile Frameworks",
        description:
          'In this course, students will be introduced to The Agile Mindset and how it sets the tone for "being" Agile versus just "doing" Agile. Students will learn to leverage The Agile Manifesto as the foundation for all Agile Frameworks, identify the practical differences between Agile and Waterfall approaches, and master characteristics of three of the more popular Agile Frameworks being utilized across all industries: Scrum, Kanban and XP. Students will then take a deep dive into Agile Teams and Governance and apply best practices of both in order to deliver immense business value.',
      },
    ],
  },
};

export default WhatYouWillLearn;
