import React, { useState } from "react";
import image1 from "../../images/Detail-academy-about-this.png";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Link } from "../../components/Link/Link";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

const AboutThisProgram = ({ data, ...props }) => {
  const [seemore, setSeemore] = useState(true);
  const { primary } = data || {};
  const handleSeemore = () => {
    setSeemore(!seemore);
  };
  if(!primary) return null;
  return (
    <WrapperSection>
      <BodyContainer>
        <BodyInfor>
          <InfoHeader
            font={typography.type.code}
            color={color.primary.blue}
            weight={typography.weight.regular1}
            align="left"
            size={typography.size.code}
            lineHeight={90}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </InfoHeader>
          <Mobile>
            <BodyImage>
              <Image
                src={primary?.image?.url}
                alt={primary?.image?.alt}
              ></Image>
            </BodyImage>
          </Mobile>
          <InforDescription>
            <Desktop>
              <Description
                font={typography.type.primary}
                weight={typography.weight.regular}
                align="left"
                size={typography.size.s3}
                lineHeight={26}
              >
                <RichText
                  render={primary?.description?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Description>
            </Desktop>
            <Mobile>
              {seemore ? (
                <Description
                  font={typography.type.primary}
                  color={color.netraul.black80}
                  weight={typography.weight.normal}
                  align="left"
                  size={typography.size.s3}
                  lineHeight={26}
                >
                  {primary?.description?.raw[0]?.text}...
                </Description>
              ) : (
                <Description
                  font={typography.type.primary}
                  color={color.netraul.black80}
                  weight={typography.weight.normal}
                  align="left"
                  size={typography.size.s3}
                  lineHeight={26}
                >
                  <RichText
                    render={primary?.description?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Description>
              )}

              <LinkSeeMore onClick={handleSeemore}>
                {seemore ? "See more" : "See less"}
              </LinkSeeMore>
            </Mobile>
          </InforDescription>
          <InforCourse>
            <InforCourseItem>
              <InforCourseItemTitle>Course cost</InforCourseItemTitle>
              <InforCourseItemDesc>
                {primary?.course_cost_prefix?.text}
                {primary?.course_cost?.text}
              </InforCourseItemDesc>
            </InforCourseItem>
            <InforCourseItem>
              <InforCourseItemTitle>TImeline</InforCourseItemTitle>
              <InforCourseItemDesc>
                {primary?.timeline?.text}
              </InforCourseItemDesc>
            </InforCourseItem>
            <InforCourseItem>
              <InforCourseItemTitle>Start Date</InforCourseItemTitle>
              <InforCourseItemDesc>{primary?.start_date}</InforCourseItemDesc>
            </InforCourseItem>
          </InforCourse>
        </BodyInfor>

        <Desktop>
          <BodyImage>
            <Image src={primary?.image?.url} alt={primary?.image?.alt}></Image>
          </BodyImage>
        </Desktop>
      </BodyContainer>
    </WrapperSection>
  );
};

const InforCourse = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;
const InforCourseItem = styled.div`
  flex: 0 0 33%;
  max-width: 33%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (min-width: 1300px) {
    &:last-child {
      padding-left: 40px;
    }
  }
  @media (max-width: 1300px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 34px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 992px) {
    flex: 0 0 33%;
    max-width: 33%;
    margin-bottom: 0;
  }
  @media (max-width: 650px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 34px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 376px) {
  }
`;
const InforCourseItemTitle = styled(Text)`
  font-family: ${typography.type.primary};
  font-style: normal;
  font-weight: normal;
  font-size: ${typography.size.s2}px;
  line-height: 22px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 14px;
`;
const InforCourseItemDesc = styled(Text)`
  font-family: ${typography.type.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${typography.size.m1}px;
  line-height: 30px;
  color: ${color.netraul.black100};
`;
const Desktop = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;

const Mobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 150px;
  padding: 0 131px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    margin-top: 80px;
    padding: 0 20px;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  margin-top: 120px;
  @media (max-width: 1200px) {
    margin-top: 80px;
  }
  @media (max-width: 992px) {
    margin-top: 60px;
  }
  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
const BodyInfor = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const InfoHeader = styled(Text)`
  box-sizing: border-box;
  padding-right: 20px;
  letter-spacing: -0.015em;
  @media (max-width: 992px) {
    padding-right: 0;
    font-size: 62px !important;
    line-height: 62px;
    margin-bottom: 30px;
    display: block;
  }
`;

const InforDescription = styled.div`
  padding-bottom: 33px;
  border-bottom: 1px solid ${color.netraul.black10};

  @media (max-width: 1200px) {
    padding-right: 60px;
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    padding-right: 30px;
    margin-top: 30px;
  }
  @media (max-width: 650px) {
    padding-right: 0;
    margin-top: 30px;
  }
`;
const Description = styled(Text)`
  margin-top: 30px;
  display: block;
  text-align: left;
  color: #000;

  @media (max-width: 1200px) {
    margin-top: 14px;
  }
`;
const LinkSeeMore = styled(Link)`
  display: inline-block;
  color: ${color.primary.blue};
  font-weight: bold;
  margin-top: 14px;
`;
const BodyImage = styled.div`
  width: 100%;
  padding-left: 123px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    padding-left: 50px;
  }
  @media (max-width: 1200px) {
    padding-left: 0px;
  }
  @media (max-width: 992px) {
    max-height: 500px;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  @media (max-width: 992px) {
    max-height: 400px;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

AboutThisProgram.propTypes = {
  data: PropTypes.shape(
    PropTypes.shape({
      cost: PropTypes.number,
      time: PropTypes.string,
      startDate: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.arrayOf(),
    })
  ),
};

AboutThisProgram.defaultProps = {
  data: {
    cost: 446,
    timeline: "Approx. 3 weeks",
    startDate: "12/04/2021",
    description: [
      "Prototyping allows you to spend ten minutes — instead of ten hours — finding an amazing design for your product. This course will guide you through the iterative process of prototyping an app and conducting user research. You will start by making a low fidelity paper prototype, conducting research with users, and using that research to inform your next iteration.",
      "Together with InVision we’ll guide you through creating interactive prototypes. This means you don’t have to code an app before putting it in front of users! Along the way, you’ll learn how and where prototyping fits into your app design process and how you can use prototyping to become a better entrepreneur.",
    ],
    image: image1,
  },
};

export default AboutThisProgram;
