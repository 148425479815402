import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Header } from '../containers/HeaderContainer/index'
import FooterContainer from '../containers/FooterContainer'
import useApp, { withAppContext } from '../contexts/context';
import SEO from '../components/SEO'
import { useSiteMetadata } from '../hooks/useQuerydata';
import Layout from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { graphql } from 'gatsby';
import { AcademyDetailContainer } from '../containers/AcademyDetailContainer';

const CourseDetail = ({ pageContext, data, ...props }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;

  const theme = {
    bgColor: 'white',
  }

  if (!data) return null
  const course = data.prismicCourseDetail.data;

  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer
            data={allPrismicFooter?.nodes[0]?.data}
          />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={course?.body8} />
        }
      >
        <AcademyDetailContainer data={course} />
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(CourseDetail);

export const query = graphql`
  query CourseQuery($id: String) {
    prismicCourseDetail(id: {eq: $id}) {
      uid
      data {
        banner {
          alt
          url
        }
        banner_title {
          html
          text
          raw
        }
        banner_description {
          html
          text
          raw
        }
        owner {
          document {
            ... on PrismicPartners {
              data {
                logo {
                  alt
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicCourseDetailBodyAboutCourse {
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              image {
                url
                alt
              }
              course_cost {
                html
                text
                raw
              }
              course_cost_prefix {
                html
                text
                raw
              }
              timeline {
                html
                text
                raw
              }
              start_date(formatString: "DD/MM/YYYY")
            }
          }
        }
        body1 {
          ... on PrismicCourseDetailBody1Why {
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              image {
                alt
                url
              }
            }
            items {
              include_in_program {
                html
                text
                raw
              }
            }
          }
        }
        body2 {
          ... on PrismicCourseDetailBody2WhatWillLearn {
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
            }
          }
        }
        body3 {
          ... on PrismicCourseDetailBody3PartnerCompanies {
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              partner {
                document {
                  ... on PrismicPartners {
                    data {
                      logo {
                        url
                        alt
                      }
                      thumbnail {
                        url
                        alt
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body4 {
          ... on PrismicCourseDetailBody4AboutTheInstructor {
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              name {
                html
                text
                raw
              }
              position {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              avatar {
                url
                alt
              }
            }
          }
        }
        body5 {
          ... on PrismicCourseDetailBody5Enroll {
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              button_label {
                html
                text
                raw
              }
              button_link {
                url
                type
                link_type
              }
            }
          }
        }
        body6 {
          ... on PrismicCourseDetailBody6Faqs {
            primary {
              title {
                html
                text
                raw
              }
            }
            items {
              question {
                html
                text
                raw
              }
              answer {
                html
                text
                raw
              }
            }
          }
        }
        body7 {
          ... on PrismicCourseDetailBody7Program {
            items {
              name {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              price {
                text
              }
            }
          }
        }
        body8 {
          ... on PrismicCourseDetailBody8GeneralCard {
            primary {
              title {
                html
                text
                raw
              }
              robot {
                html
                text
                raw
              }
              image {
                url
                alt
              }
              googlebot {
                html
                text
                raw
              }
              favicon {
                alt
                url
              }
              description {
                html
                text
                raw
              }
              bingbot {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicCourseDetailBody8OpenGraph {
            primary {
              og_url {
                url
                type
                link_type
              }
              og_type {
                html
                text
                raw
              }
              og_title {
                html
                text
                raw
              }
              og_site_name {
                html
                text
                raw
              }
              og_locale {
                html
                text
                raw
              }
              og_image_width {
                html
                text
                raw
              }
              og_image_height {
                html
                text
                raw
              }
              og_image {
                alt
                url
              }
              og_description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicCourseDetailBody8TwitterCard {
            primary {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
              }
              description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicCourseDetailBody8PinterestProductPin {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              price
              currency
              availability
            }
          }
        }
      }
    }
  }
`
