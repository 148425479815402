import React from "react";
import image1 from "../../images/Detail-academy-why-take.png";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import useWindowSize from "./useWindowSize";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

const WhyTakeThisProgram = ({ data, ...props }) => {
  const [width] = useWindowSize();
  const { items, primary } = data || {};
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Wrapper>
        {width > 992 && (
          <WrapImage>
            <Image src={primary?.image?.url} alt={primary?.image?.alt}></Image>
          </WrapImage>
        )}

        <WrapInfo>
          <Title
            tag="h3"
            font={typography.type.code}
            color={color.primary.white}
            weight={typography.weight.regular1}
            align="left"
            size={typography.size.code}
            lineHeight={90}
            capital="uppercase"
          >
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          {width <= 992 && (
            <WrapImage>
              <Image src={primary?.image?.url} alt="image"></Image>
            </WrapImage>
          )}

          <WrapDescription>
            <Description
              tag="p"
              font={typography.type.primary}
              color={color.primary.blue20}
              weight={typography.weight.regular}
              align="left"
              size={typography.size.s3}
              lineHeight={26}
            >
              <RichText
                render={primary?.description?.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
          </WrapDescription>
          <WrapList>
            <TitleList
              tag="p"
              font={typography.type.primary}
              color={color.primary.white}
              weight={typography.weight.regular}
              align="left"
              size={typography.size.s2}
              lineHeight={22}
              capital="uppercase"
            >
              INCLUDED IN Program
            </TitleList>
            <List>
              {items &&
                items.map((item, index) => (
                  <ListItem key={index}>
                    <Text
                      font={typography.type.primary}
                      color={color.primary.white}
                      weight={typography.weight.bold}
                      align="left"
                      size={typography.size.m1}
                      lineHeight={28}
                    >
                      {item?.include_in_program?.text}
                    </Text>
                  </ListItem>
                ))}
            </List>
          </WrapList>
        </WrapInfo>
      </Wrapper>
    </WrapperSection>
  );
};

const ListItem = styled.li`
  margin-bottom: 19px;
  margin-right: 50px;
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${color.secondary.emerald};
      position: absolute;
      left: -20px;
      top: 8px;
    }
  }
`;
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`;
const TitleList = styled(Text)`
  letter-spacing: 0.2em;
  margin-bottom: 24px;
  opacity: 0.5;
  @media (max-width: 650px) {
    font-weight: 400;
  } ;
`;
const WrapList = styled.div`
  padding-top: 32px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    padding-top: 24px;
  }
`;
const Description = styled(Text)`
  width: 50%;
  box-sizing: border-box;
  &:first-child {
    span {
      &:first-child {
        padding-right: 33px;
        @media (max-width: 650px) {
          padding-right: 0;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 24px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-bottom: 0;
    display: block !important;
    span {
      color: #e9ecf1;
      font-weight: normal;
    }
  }
`;
const WrapDescription = styled.div`
  margin-top: 60px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  p {
    width: 100%;
    display: flex;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 30px;
    padding-bottom: 24px;
  }
`;
const Title = styled(Text)`
  @media (max-width: 992px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapInfo = styled.div`
  padding: 120px 90px 101px;
  @media (max-width: 1200px) {
    padding: 60px 20px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapImage = styled.div`
  flex: 0 0 39%;
  max-width: 39%;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    margin-top: 40px;
    flex: 0 0 100%;
    max-width: 100%;
    height: 330px;
  }
  @media (max-width: 650px) {
    height: 220px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  background-color: ${color.secondary.danube};
`;
const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 150px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0px;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    padding: 0px;
  }
  @media (max-width: 650px) {
    margin-top: 80px;
    padding: 0px;
  }
`;

WhyTakeThisProgram.propTypes = {
  data: PropTypes.shape(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      descriptions: PropTypes.arrayOf(),
      includes: PropTypes.arrayOf(),
    })
  ),
};

WhyTakeThisProgram.defaultProps = {
  data: {
    title: "Why take this program",
    descriptions: [
      "Prototyping is an art that exists to save you time and money in the app development process. It allows you to identify design flaws quickly so you waste as little time as possible building a phenomenal app. By learning this art, you’ll create quality apps faster and have confidence in the viability of your products.",
      "By the end of the course, you’ll be comfortable with the prototyping process, be able to get quality user feedback, and use the feedback to create great apps. Lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
    ],
    includes: [
      "Taught by Industry Pros",
      "Certificate",
      "Learn by doing exercies",
      "Team work experience",
      "Strong foundation",
      "Instructor videos",
    ],
    image: image1,
  },
};

export default WhyTakeThisProgram;
