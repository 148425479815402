import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import {redirectPage} from "../../hooks/redirect"

const EnrollNowBannner = ({ enroll }) => {
  return (
    <WrapperSection>
      <Wrapper>
        <WrapFooter>
          <FooterName
            font={typography.type.code}
            color={color.primary.white}
            weight={typography.weight.regular}
            align="left"
            size={typography.size.code}
            lineHeight={90}
            capital="uppercase"
          >
            {enroll?.primary?.title?.text}
          </FooterName>
          <FooterAction>
            <FooterActionName
              font={typography.type.primary}
              color={color.primary.white}
              weight={typography.weight.bold}
              align="left"
              size={typography.size.s3}
              lineHeight={26}
            >
              {enroll?.primary?.description?.text}
            </FooterActionName>
            <ButtonExpand
              onClick={() => 
                redirectPage({
                  url: enroll?.primary?.button_link?.url,
                  type: enroll?.primary?.button_link?.type,
                  link_type: enroll?.primary?.button_link?.link_type
                })
              }
              type="tertiary"
            >
              {enroll?.primary?.button_label?.text}
            </ButtonExpand>
          </FooterAction>
        </WrapFooter>
      </Wrapper>
    </WrapperSection>
  );
};

const FooterActionName = styled(Text)`
  @media (max-width: 650px) {
    text-align: center;
  }
`;
const ButtonExpand = styled(Button)`
  display: block;
  padding: 20px 45px;
  border-radius: 40px;
  margin-top: 41px;
`;
const FooterAction = styled.div`
  padding-left: 120px;
  width: 20%;
  @media (max-width: 1200px) {
    width: auto;
  }
  @media (max-width: 992px) {
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
  }
  @media (max-width: 650px) {
  }
`;
const FooterName = styled(Text)`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  @media (max-width: 1400px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
  @media (max-width: 1200px) {
    font-size: 62px !important;
    line-height: 62px;
  }
  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  @media (max-width: 650px) {
  }
`;
const WrapFooter = styled.div`
  display: flex;
  padding: 70px 90px;
  background-color: ${color.primary.blue};
  @media (max-width: 1200px) {
    bottom: -418px;
  }
  @media (max-width: 992px) {
    bottom: -472px;
    padding: 60px auto;
    flex-direction: column;
  }
  @media (max-width: 650px) {
    bottom: -500px;
    padding: 60px 20px;
  }
  @media (max-width: 376px) {
    bottom: -600px;
  }
`;

const Wrapper = styled.div`
  color: blue;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  padding: 0 131px;
  background: linear-gradient(to bottom, ${color.primary.blue10} 50%, #fff 50%);
  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    padding: 0 20px 0;
  }
`;

export default EnrollNowBannner;
