import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import banner from "../../images/Detail-academy-banner.png";
import { color, typography } from "../../shared/style";
export const Banner = ({
  banner,
  title,
  description,
  owner,
  programs,
  seo,
}) => {
  const redirectPage = (url) => {
    navigate(`/${url}`);
    typeof sessionStorage !== "undefined" &&
      sessionStorage.setItem(
        "dataCheckout",
        JSON.stringify({ title, programs, seo })
      );
  };

  return (
    <WrapperSection banner={banner?.url}>
      <Content>
        <TextExtend
          tag="h2"
          align="left"
          capital="uppercase"
          color={color.primary.white}
          font={typography.type.code}
          weight={typography.weight.regular1}
          size={typography.size.code}
          lineHeight={90}
        >
          {title?.text}
        </TextExtend>
        <WrapImage>
          <Text
            color={color.primary.white}
            weight={typography.weight.bold}
            font={typography.type.primary}
          >
            By{" "}
          </Text>
          <Image src={owner?.logo?.url} alt="image"></Image>
        </WrapImage>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="left"
          size={typography.size.s3}
          lineHeight={26}
        >
          {description?.text}
        </Description>
        <GroupButton>
          <ButtonExtended
            onClick={() => redirectPage("checkout")}
            type="primary"
          >
            Enroll now
          </ButtonExtended>
        </GroupButton>
      </Content>
    </WrapperSection>
  );
};

const Image = styled.img`
  max-width: 148px;
  width: 148px;
  max-height: 50px;
  height: 50px;
  object-fit: cover;
`;

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  span {
    display: inline-block;
    margin-right: 18px;
  }
`;

const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    margin-bottom: 70px;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: 40px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
`;
const TextExtend = styled(Text)`
  letter-spacing: -0.015em;
  margin-top: 153px;
  @media (max-width: 1200px) {
    padding: 20px 0;
    font-size: 74px !important;
    line-height: 74px;
  }
  @media (max-width: 650px) {
    padding: 0;
    margin-top: 75px;
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapperSection = styled.div`
  min-height: 610px;
  box-sizing: border-box;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  @media (max-width: 650px) {
    min-height: 420px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const Description = styled(Text)`
  width: 60%;
  margin-top: 24px;
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 95%;
    margin-top: 12px;
  }
`;
