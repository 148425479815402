import React from "react";
import styled from "styled-components";
import AboutInstructor from "./AboutInstructor";
import AboutPartner from "./AboutPartner";
import AboutThisProgram from "./AboutThisProgram";
import AskQuesstion from "./AskQuesstion";
import { Banner } from "./Banner";
import EnrollNowBannner from "./EnrollNowBannner";
import WhatYouWillLearn from "./WhatYouWillLearn";
import WhyTakeThisProgram from "./WhyTakeThisProgram";

export const AcademyDetailContainer = ({ data }) => {
  const programs = data?.body7[0]?.items;

  return (
    <WrapperLayout>
      <Banner
        banner={data?.banner}
        title={data?.banner_title}
        description={data?.banner_description}
        owner={data?.owner?.document?.data}
        button={data?.banner_button?.text}
        programs={programs}
        seo={data?.body8}
      />
      {data?.body[0] && (
      <AboutThisProgram
        data={data?.body[0] === undefined ? {} : data?.body[0]}
      />
      )}
      {data?.body1[0] && (
      <WhyTakeThisProgram
        data={data?.body1[0] === undefined ? {} : data?.body1[0]}
      />
      )}
      {data?.body2[0] && (
      <WhatYouWillLearn
        data={data?.body2[0] === undefined ? {} : data?.body2[0]}
      />
      )}
      {data?.body3[0] && (
      <AboutPartner data={data?.body3[0] === undefined ? {} : data?.body3[0]} />
      )}
      {data?.body4[0] && (
      <AboutInstructor
        data={data?.body4[0] === undefined ? {} : data?.body4[0]}
      />
      )}
      {data?.body5[0] && (
      <EnrollNowBannner
        enroll={data?.body5[0] === undefined ? {} : data?.body5[0]}
      />
      )}
      {data?.body6[0] && (
      <AskQuesstion data={data?.body6[0] === undefined ? {} : data?.body6[0]} />
      )}
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
`;
