import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import ArrowLeft from "../../images/arrow-left.svg";
import ArrowRight from "../../images/arrow-right.svg";
import image1 from "../../images/Detail-academy-airbnb-intrustor.png";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";

const AboutInstructor = ({ data, ...props }) => {
  const [indexItem, setIndexItem] = useState(0);
  const { items, primary } = data || {};
  const handleChangeItem = (index) => {
    setIndexItem(index);
  };
  if(!items || !primary) return null;
  return (
    <WrapperSection>
      <Wrapper>
        <Wraptitle>
          <TitleHeader
            font={typography.type.code}
            color={color.primary.blue}
            weight={typography.weight.regular1}
            align="left"
            size={typography.size.code}
            lineHeight={90}
            capital="uppercase"
          >
            <RichText
              render={primary?.title.raw}
              htmlSerializer={htmlSerializer}
            />
          </TitleHeader>
          <WrapActionHeader>
            <WrapArrow>
              <SlideButton
                onClick={() =>
                  handleChangeItem(
                    indexItem < 1 ? items.length - 1 : indexItem - 1
                  )
                }
              >
                <Image src={ArrowLeft} alt="image"></Image>
              </SlideButton>
              <SlideButton
                onClick={() =>
                  handleChangeItem(
                    indexItem < items.length - 1 ? indexItem + 1 : 0
                  )
                }
              >
                <Image src={ArrowRight} alt="image"></Image>
              </SlideButton>
            </WrapArrow>
            <WrapImage>
              <Image src={items[indexItem]?.avatar?.url} alt="image"></Image>
            </WrapImage>
          </WrapActionHeader>
        </Wraptitle>
        <WrapContent>
          <Content>
            <ContentTitle
              tag="h4"
              font={typography.type.code}
              color={color.primary.white}
              weight={typography.weight.regular1}
              align="left"
              size={typography.size.l3}
              lineHeight={56}
              capital="uppercase"
            >
              {items[indexItem]?.name?.text}
            </ContentTitle>
            <ContentPosition
              font={typography.type.primary}
              color={color.primary.white}
              weight={typography.weight.bold}
              align="left"
              size={typography.size.s3}
              lineHeight={26}
            >
              {items[indexItem]?.position?.text}
            </ContentPosition>
            <WrapDescription>
              <Description
                font={typography.type.primary}
                color={color.primary.white}
                weight={typography.weight.normal}
                align="left"
                size={typography.size.s3}
                lineHeight={26}
              >
                <RichText
                  render={items[indexItem].description.raw}
                  htmlSerializer={htmlSerializer}
                />
              </Description>
            </WrapDescription>
          </Content>
        </WrapContent>
      </Wrapper>
    </WrapperSection>
  );
};

const SlideButton = styled.div`
  width: 60px;
  height: 60px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${color.primary.white};
  border: 1px solid transparent;
  transition: all 0.4s ease;
  &:hover {
    border-color: #000;
  }
  &:last-child {
    margin-left: 4px;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    background: rgba(23, 41, 64, 0.05);
  }
`;
const WrapArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-top: 30px;
  }
  @media (max-width: 650px) {
  }
`;
const WrapActionHeader = styled.div``;

const Description = styled(Text)`
  display: block;
  margin-top: 24px;
  div {
    margin-bottom: 12px;
  }
`;
const WrapDescription = styled.div`
  margin-top: 48px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    margin-top: 24px;
  }
  @media (max-width: 650px) {
  }
`;
const ContentPosition = styled(Text)`
  display: block;
  opacity: 0.8;
  margin-top: 14px;
`;
const ContentTitle = styled(Text)`
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 36px !important;
    line-height: 36px;
  }
`;
const Content = styled.div`
  padding: 86px 90px;
  background-color: ${color.secondary.danube};
  @media (max-width: 1200px) {
    padding: 60px 30px;
  }
  @media (max-width: 992px) {
    padding: 40px 20px;
  }
  @media (max-width: 650px) {
  }
`;
const WrapContent = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const WrapImage = styled.div`
  height: 210px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    width: 100%;
    height: 148px;
  }
`;
const TitleHeader = styled(Text)`
  span {
    font-weight: 500;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    font-size: 62px !important;
    line-height: 62px;
  }
  @media (max-width: 650px) {
  }
`;
const Wraptitle = styled.div`
  flex: 0 0 40%;
  max-width: 40%;
  box-sizing: border-box;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0px;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 1200px) {
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 30px;
  padding: 150px 131px 150px;
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 80px 40px 80px;
  }
  @media (max-width: 992px) {
    padding: 80px 30px 80px;
  }
  @media (max-width: 650px) {
    padding: 80px 20px 80px;
  }
`;

AboutInstructor.propTypes = {
  data: PropTypes.shape(
    PropTypes.shape({
      cost: PropTypes.number,
      time: PropTypes.string,
      startDate: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.shape({
        raw: PropTypes.arrayOf(),
      }),
    })
  ),
};

AboutInstructor.defaultProps = {
  data: {
    title: {
      raw: [
        {
          type: "preformatted",
          text: "ABOUT <span style='color:#204370'>INSTRUCTORS<span>",
          spans: [],
        },
      ],
    },
    list: [
      {
        id: "1",
        name: "Jane Cooper",
        image: image1,
        position: "Co-founder & CEO Travellio",
        description: {
          raw: [
            {
              type: "paragraph",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at erat id nisl convallis placerat in elementum mauris. Curabitur mollis tempus orci, eu egestas ante pretium vel. Aenean volutpat augue vitae enim pharetra vestibulum.",
              spans: [],
            },
            {
              type: "paragraph",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at erat id nisl convallis placerat in elementum mauris. Curabitur mollis tempus orci, eu egestas ante pretium vel.",
              spans: [],
            },
            {
              type: "paragraph",
              text:
                "Curabitur mollis tempus orci, eu egestas ante pretium vel. Aenean volutpat augue vitae enim pharetra vestibulum. Ut eleifend pulvinar dolor, a dapibus arcu consequat maximus. ",
              spans: [],
            },
          ],
        },
      },
      {
        id: "2",
        name: "Jane Cooper 2",
        image: image1,
        position: "Co-founder & CEO Travellio 2",
        description: {
          raw: [
            {
              type: "paragraph",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at erat id nisl convallis placerat in elementum mauris. Curabitur mollis tempus orci, eu egestas ante pretium vel. Aenean volutpat augue vitae enim pharetra vestibulum.",
              spans: [],
            },
            {
              type: "paragraph",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at erat id nisl convallis placerat in elementum mauris. Curabitur mollis tempus orci, eu egestas ante pretium vel.",
              spans: [],
            },
            {
              type: "paragraph",
              text:
                "Curabitur mollis tempus orci, eu egestas ante pretium vel. Aenean volutpat augue vitae enim pharetra vestibulum. Ut eleifend pulvinar dolor, a dapibus arcu consequat maximus. ",
              spans: [],
            },
          ],
        },
      },
    ],
  },
};

export default AboutInstructor;
