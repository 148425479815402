import React, { useState } from "react";
import imageGoogleTitle from "../../images/Detail-academy-google.png";
import imageGoogle from "../../images/Detail-academy-google-intrustor.png";
import imageAirbnbTitle from "../../images/Detail-academy-airbnb.png";
import imageAirbnb from "../../images/Detail-academy-airbnb-full.png";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Link } from "../../components/Link/Link";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import useWindowSize from "./useWindowSize";

const AboutPartner = ({ data, ...props }) => {
  const [width] = useWindowSize();
    const [seeMore, setSeemore] = useState({ step: -1, status: false });
    const { items, primary } = data || {};

    const handleSeemore = (step) => {
      setSeemore((pre) => {
        if (pre.step === step) {
          return { ...pre, step: step, status: !pre.status };
        } else return { ...pre, step: step, status: true };
      });
    };

    const Item = ({ data, i }) => (
      <WrapperContent isLeft={i % 2 === 0}>
        {width <= 650 && (
          <ImageTitle>
            <Image src={data.logo?.url} alt="image"></Image>
          </ImageTitle>
        )}
        <WrapImage isLeft={i % 2 === 0}>
          <Image
            isLeft={i % 2 === 0}
            color={
              i % 2 !== 0 ? color.primary.blue : color.secondary.yellowcream
            }
            src={data.thumbnail?.url}
          ></Image>
        </WrapImage>
        <Content isLeft={i % 2 === 0}>
          {width > 650 && (
            <ImageTitle>
              <Image src={data?.logo?.url} alt="image"></Image>
            </ImageTitle>
          )}
          <WrapDescription>
            <Description
              tag="p"
              weight={typography.weight.normal}
              color={color.netraul.black100}
              font="Oxygen"
              lineHeight={26}
              style={typography.size.s3}
              className={
                seeMore.step === i && seeMore.status === true
                  ? ""
                  : width < 1200
                  ? "text-clamp"
                  : ""
              }
            >
              <RichText
                render={data?.description.raw}
                htmlSerializer={htmlSerializer}
              />
            </Description>
          </WrapDescription>
          {width <= 1200 && (
            <SeeMore onClick={() => handleSeemore(i)}>
              {seeMore.step === i && seeMore.status === true
                ? "See less"
                : "See more"}
            </SeeMore>
          )}
        </Content>
      </WrapperContent>
    );
  if(!items || !primary) return null;
  return (
    <WrapperSection>
      <WrapHeader>
        <TitleHeader
          font={typography.type.code}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.code}
          lineHeight={90}
          capital="uppercase"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </TitleHeader>
      </WrapHeader>
      <WrapBody>
        {items &&
          items.map((data, index) => (
            <Item
              data={data?.partner?.document?.data}
              key={index}
              i={index + 1}
            />
          ))}
      </WrapBody>
    </WrapperSection>
  );
};

const SeeMore = styled(Link)`
  color: ${color.primary.blue};
  font-weight: bold;
  display: inline-block;
  border-bottom: 1px solid ${color.primary.blue};
  margin-top: 14px;
`;
const WrapDescription = styled.div``;
const ImageTitle = styled.div`
  width: 172px;
  @media (max-width: 650px) {
    margin-bottom: 24px;
  }
`;
const WrapBody = styled.div`
  margin-top: 100px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    margin-top: 52px;
  }
`;
const TitleHeader = styled(Text)`
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapHeader = styled.div`
  text-align: center;
  padding: 0 131px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    text-align: left;
    padding: 0;
  }
`;

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 130px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
  .text-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
  }
`;

const WrapperContent = styled.div`
  background-color: ${color.primary.white};
  display: flex;
  margin-bottom: 150px;
  flex-direction: ${(props) => (props.isLeft ? "row" : "row-reverse")};
  @media (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

const WrapImage = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  ${(props) => (props.isLeft ? `padding-left:41px;` : `padding-right:40px; `)};
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  ${(props) =>
    props.isLeft
      ? `box-shadow: -41px 54px 1px ${props.color};`
      : `box-shadow: 41px 54px 1px ${props.color}`};

  @media (max-width: 992px) {
    ${(props) =>
      props.isLeft
        ? `box-shadow: -41px 54px 1px ${props.color};`
        : `box-shadow: 41px 54px 1px ${props.color}`};
  }
  @media (max-width: 650px) {
    ${(props) =>
      props.isLeft
        ? `box-shadow: -20px 26px 1px ${props.color};`
        : `box-shadow: 20px 26px 1px ${props.color}`};
  }
`;

const Content = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  ${(props) =>
    props.isLeft
      ? `padding-left: 125px; padding-right: 97px;`
      : `padding-left: 131px; padding-right: 91px`};

  @media (max-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
    padding: 0;
  }
`;

const Description = styled(Text)`
  margin-top: 24px;
  div {
    margin-bottom: 12px;
  }
  @media (max-width: 650px) {
    margin-top: 24px;
  } ;
`;

AboutPartner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        imageTitle: PropTypes.string,
        imageBg: PropTypes.string,
        isLeft: PropTypes.bool,
        descriptions: PropTypes.arrayOf(),
      })
    ),
  }),
};

AboutPartner.defaultProps = {
  data: {
    title: "What you’ll learn",
    list: [
      {
        id: "1",
        imageTitle: imageGoogleTitle,
        imageBg: imageGoogle,
        isLeft: false,
        shadowColor: color.primary.blue,
        description: {
          html:
            "<p>Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California.Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware. It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft. The company began as an online search firm, but it now offers more than 50 Internet services and products, from e-mail and online document creation to software for mobile phones and tablet computers and also Google Calendar, Google Maps, Google Drive, Google Docs, Google Photos, Google Translate etc.</p>",
          text:
            "Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California.Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware. It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft. The company began as an online search firm, but it now offers more than 50 Internet services and products, from e-mail and online document creation to software for mobile phones and tablet computers and also Google Calendar, Google Maps, Google Drive, Google Docs, Google Photos, Google Translate etc.",
          raw: [
            {
              type: "preformatted",
              text:
                "It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.",
              spans: [],
            },
          ],
        },
      },
      {
        id: "2",
        imageTitle: imageAirbnbTitle,
        imageBg: imageAirbnb,
        isLeft: true,
        shadowColor: color.secondary.yellowcream,
        description: {
          html:
            "<p>Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California.<br></br>Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.<br></br> It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft.<br></br> The company began as an online search firm, but it now offers more than 50 Internet services and products, from e-mail and online document creation to software for mobile phones and tablet computers and also Google Calendar, Google Maps, Google Drive, Google Docs, Google Photos, Google Translate etc.</p>",
          text:
            "Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California.<br></br>Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.<br></br> It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft.<br></br> The company began as an online search firm, but it now offers more than 50 Internet services and products, from e-mail and online document creation to software for mobile phones and tablet computers and also Google Calendar, Google Maps, Google Drive, Google Docs, Google Photos, Google Translate etc.",
          raw: [
            {
              type: "preformatted",
              text:
                "It is the most visited website in the world and it is considered one of the Big Four technology companies along with Amazon, Apple and Microsoft",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google was founded in 1998 by Larry Page and Sergey Brin and it is headquartered in Mountain View, California",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Google is an American multinational technology company specialized in internet services and products like online advertising technologies, a search engine, cloud computing, software, and hardware.",
              spans: [],
            },
          ],
        },
      },
    ],
  },
};

export default AboutPartner;
